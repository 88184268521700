import config from "../_config";
import { setHeader } from '../_helper/apiHeader'
import Axios from "axios";
import { getResponseData } from "../_helper/getResponseData";

const BASE_API_URL = config.BASE_API_URL;


export async function getFoodServiceData(params, pageNum = 1) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/food/home-screen?page=${pageNum}`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    throw err;
  };
}

export async function getRestaurantData(params) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/food/product-list`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    return { response: null, error: err }
  };
}

export async function addProductToCart(params) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/food/save-product-cart`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    return { response: null, error: err }
  };
}

export async function deleteProductFromCart(params) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/food/delete-product-cart`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    return { response: null, error: err }
  };
}

export async function getPromoCodes(params) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/get-promo-code`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    return { response: null, error: err }
  };
}

export async function applyFoodPromoCode(params) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/food/apply-remove-promo-code`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    return { response: null, error: err }
  };
}

export async function placeFoodOrder(params) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/food/place-order`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    return { response: null, error: err }
  };
}

export async function getFoodOrderHistory(params) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/food/get-orders`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    return { response: null, error: err }
  };
}

export async function getFoodOrderSummary(params) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/food/get-order-details`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    return { response: null, error: err }
  };
}

// export async function getFoodOrderCancelReasons(params) {
//   try {
//     const res = await Axios.post(`${BASE_API_URL}user/cancel-reasons`, params, setHeader());
//     const { response } = getResponseData(res['data'])
//     return { response, error: null };
//   } catch (err) {
//     return { response: null, error: err }
//   };
// }

export async function cancelFoodGroceryOrder(params) {
  try {
    const res = await Axios.post(`${BASE_API_URL}user/order-cancel`, params, setHeader());
    const { response } = getResponseData(res['data'])
    return { response, error: null };
  } catch (err) {
    return { response: null, error: err }
  };
}




